//Zona de importacion de componentes externos.
import React, { useEffect, useState } from "react";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import "./static/css/Detalle/Detalle.css";
import Producto from "./Producto";
import { useParams } from "react-router";
import axios from "axios";
import GrupoTallas from "./GrupoTallas";
import { Link } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import $ from "jquery";
import Footer from "../Footer/Footer";

const Detalle = ({
  ViewDetalle,
  SetViewDetalle,
  SetLandingHeader,
  headerState,
  modificarHeaderState,
  filtrosState,
  modificarFiltrosState,
  productosState,
  modificarProductosState,
  tallasState,
  modificarTallasState,
  coloresState,
  modificarColoresState,
  marcasState,
  modificarMarcasState,
  carrito,
  modificarCarritoState,
  cliente,
  errorState,
  modificarErrorState,
  spinnerState,
  modificarSpinnerState,
  apiUrlState,
  modificarApiUrlState,
  recursosUrlState,
  modificarRecursosUrlState,
  clienteState,
  modificarClienteState,
  categoriasHombreState,
  modificarCategoriasHombreState,
  categoriasMujerState,
  modificarCategoriasMujerState,
  CatalogoState,
  actualizarSessionStorage,
  estadoBar,
}) => {
  const [Product, GetProduct] = useState({});
  const [ProductRel, GetProductRel] = useState([]);
  const [ProductAux, GetProductAux] = useState();
  const [foto1, setFoto1] = useState({ f1: false });
  const [foto2, setFoto2] = useState({ f2: false });
  const [foto3, setFoto3] = useState({ f3: false });
  let { id } = useParams();

  const ProductoDetalle = async () => {
    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: " Buscando Producto...",
      });
    }
    var genero = "";
    await axios
      .get(apiUrlState + "/Api/Producto/BusquedaPalabraNombre/" + id, {}, {})
      .then((response) => {
        genero = response.data[0].data[0].Genero.Genero;
        GetProduct(response.data[0].data[0]);
      })
      .catch(function (err) {
        console.log("Error");
      });

    /*const generoState = await axios.get(apiUrlState + '/Api/Producto/BusquedaPalabraNombre/'+genero, {});
        GetProductRel(generoState.data.data)*/
    Relacionados(genero);
  };
  const calcularDescuento = (precio, descuento) => {
    const precioInt = arreglarPrecio(precio);
    const descuentoAplicar = parseFloat(descuento);
    return formatNumber(precioInt - precioInt * (descuentoAplicar / 100));
  };

  const arreglarPrecio = (precio) => {
    var separacion = precio;
    if (precio.indexOf("$") !== -1) separacion = precio.split(" ")[1];
    var separacion2 = separacion.split(".");
    return parseFloat(separacion2[0] + separacion2[1]);
  };

  //Añade el punto de miles al precio ingresado y lo retorna.
  const formatNumber = (amount) => {
    var temp = amount + "";
    var i = temp.length - 3;
    while (i > 0) {
      temp = temp.substring(0, i) + "." + temp.substring(i);
      i -= 3;
    }
    return " " + temp;
  };

  const rotacionFlecha = (codigo) => {
    var listaClases = document.getElementById("Flecha" + codigo);
    if (listaClases.classList.contains("giroFlechaArriba")) {
      listaClases.classList.remove("giroFlechaArriba");
      listaClases.classList.add("giroFlechaAbajo");
    } else {
      listaClases.classList.remove("giroFlechaAbajo");
      listaClases.classList.add("giroFlechaArriba");
    }
  };

  const Relacionados = async (genero) => {
    await axios
      .get(
        apiUrlState + "/Api/Producto/BusquedaPalabraNombre/" + genero,
        {},
        {}
      )
      .then((response) => {
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        GetProductRel(response.data[0].data);
        GetProductAux(
          Math.floor(Math.random() * (response.data[0].data.length - 1))
        );
        //Getrelacionados(true)
      })
      .catch(function (err) {
        console.log("Error 2");
      });
  };

  const VerificarFotos = (codigo, tipo) => {
    let img = document.createElement("img");
    img.src = recursosUrlState + "/img/Productos/Ref " + codigo + "1.webp";
    img.onerror = function () {
      setFoto1({ f1: true });
    };
    let imgD = document.createElement("img");
    imgD.src = recursosUrlState + "/img/Productos/Ref " + codigo + "2.webp";
    imgD.onerror = function () {
      setFoto2({ f2: true });
    };
    let imgD2 = document.createElement("img");
    imgD2.src = recursosUrlState + "/img/Productos/Ref " + codigo + "3.webp";
    imgD2.onerror = function () {
      setFoto3({ f3: true });
    };
    return "/img/Productos/Ref" + tipo + " " + codigo + ".jpg";
  };

  useEffect(() => {
    ProductoDetalle();
    //SetLandingHeader({"Estado": true})
    SetViewDetalle({ Estado: true });
    VerificarFotos(id, "T");
    modificarHeaderState({ ...headerState, Cliente: "Minoristas" });
  }, []);

  $(document).ready(function () {
    // active thumbnail
    $("#thumbSlider .thumb").on("click", function () {
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
    });
  });

  return (
    <div className="ContenedorDetalle">
      {Product.PrecioMinorista !== undefined ? (
        <div className="container" style={{ maxWidth: "100%" }}>
          <div className="row">
            <div
              className="col-4 col-md-4 col-sm-4 col-lg-2"
              style={{ textAlign: "center" }}
            >
              <div
                id="thumbSlider"
                className="carousel slide detalleOculto"
                data-interval="false"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "100%",
                        flexWrap: "nowrap",
                        rowGap: "4px",
                      }}
                    >
                      <div
                        data-target="#myCarousel"
                        data-slide-to="0"
                        className="thumb row active"
                      >
                        <img
                          className="ImgRelacionados "
                          src={
                            recursosUrlState +
                            "/img/Productos/Ref%20" +
                            id +
                            ".webp"
                          }
                          alt="XZ"
                        />
                      </div>

                      {foto1.f1 ? null : (
                        <div
                          data-target="#myCarousel"
                          data-slide-to="1"
                          className="thumb row"
                        >
                          <img
                            className="ImgRelacionados "
                            src={
                              recursosUrlState +
                              "/img/Productos/Ref%20" +
                              id +
                              "1.webp"
                            }
                            alt="XZ"
                          />
                        </div>
                      )}

                      {foto2.f2 ? null : (
                        <div
                          data-target="#myCarousel"
                          data-slide-to="2"
                          className="thumb row"
                        >
                          <img
                            className="ImgRelacionados "
                            src={
                              recursosUrlState +
                              "/img/Productos/Ref%20" +
                              id +
                              "2.webp"
                            }
                            alt="XZ"
                          />
                        </div>
                      )}

                      {foto3.f3 ? null : (
                        <div
                          data-target="#myCarousel"
                          data-slide-to="3"
                          className="thumb row"
                        >
                          <img
                            className="ImgRelacionados "
                            src={
                              recursosUrlState +
                              "/img/Productos/Ref%20" +
                              id +
                              "3.webp"
                            }
                            alt="XZ"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-12 col-md-8 col-sm-12 col-lg-5"
              style={{ textAlign: "center" }}
            >
              <div
                id="myCarousel"
                className="carousel slide"
                data-ride="carousel"
              >
                <div
                  className="carousel-inner altura"
                  style={{ width: "100%" }}
                >
                  <div
                    className="carousel-item active altura"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <img
                      className="d-block"
                      src={
                        recursosUrlState +
                        "/img/Productos/Ref%20" +
                        id +
                        ".webp"
                      }
                      alt="First slide"
                    />
                  </div>

                  {foto1.f1 ? null : (
                    <div className="carousel-item altura">
                      <img
                        className="d-block"
                        src={
                          recursosUrlState +
                          "/img/Productos/Ref%20" +
                          id +
                          "1.webp"
                        }
                        alt="Second slide"
                      />
                    </div>
                  )}

                  {foto2.f2 ? null : (
                    <div className="carousel-item altura">
                      <img
                        className="d-block"
                        src={
                          recursosUrlState +
                          "/img/Productos/Ref%20" +
                          id +
                          "2.webp"
                        }
                        alt="Third slide"
                      />
                    </div>
                  )}

                  {foto3.f3 ? null : (
                    <div className="carousel-item altura">
                      <img
                        className="d-block"
                        src={
                          recursosUrlState +
                          "/img/Productos/Ref%20" +
                          id +
                          "3.webp"
                        }
                        alt="Fourth slide"
                      />
                    </div>
                  )}
                </div>
              </div>

              <a
                className="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
            <div
              className="col-12 col-md-12 col-sm-12 col-lg-12 detalleOculto2"
              style={{ textAlign: "center", display: "none" }}
            >
              <div
                id="thumbSlider"
                className="carousel slide "
                data-interval="false"
              >
                <div className="carousel-inner ">
                  <div
                    className="carousel-item active"
                    style={{ height: "auto", width: "100%" }}
                  >
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        height: "50%",

                        flexDirection: "row",
                        columnGap: "3rem",
                      }}
                    >
                      <div
                        data-target="#myCarousel"
                        data-slide-to="0"
                        className="thumb row active"
                      >
                        <img
                          className="ImgRelacionados "
                          src={
                            recursosUrlState +
                            "/img/Productos/Ref%20" +
                            id +
                            ".webp"
                          }
                          alt="XZ"
                        />
                      </div>

                      {foto1.f1 ? null : (
                        <div
                          data-target="#myCarousel"
                          data-slide-to="1"
                          className="thumb row"
                        >
                          <img
                            className="ImgRelacionados "
                            src={
                              recursosUrlState +
                              "/img/Productos/Ref%20" +
                              id +
                              "1.webp"
                            }
                            alt="XZ"
                          />
                        </div>
                      )}

                      {foto2.f2 ? null : (
                        <div
                          data-target="#myCarousel"
                          data-slide-to="2"
                          className="thumb row"
                        >
                          <img
                            className="ImgRelacionados "
                            src={
                              recursosUrlState +
                              "/img/Productos/Ref%20" +
                              id +
                              "2.webp"
                            }
                            alt="XZ"
                          />
                        </div>
                      )}

                      {foto3.f3 ? null : (
                        <div
                          data-target="#myCarousel"
                          data-slide-to="3"
                          className="thumb row"
                        >
                          <img
                            className="ImgRelacionados "
                            src={
                              recursosUrlState +
                              "/img/Productos/Ref%20" +
                              id +
                              "3.webp"
                            }
                            alt="XZ"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-sm-12 col-lg-5">
              <div style={{ marginTop: "5px" }}>
                <h6
                  className="FuenteBebas sinMargenes letero"
                  style={{ fontWeight: "bold" }}
                >
                  {Product.Categoria.Categoria} {Product.Marca.Marca}
                </h6>
              </div>
              <div>
                <h6 className="FuenteHelvetica sinMargenes letero">
                  Ref {Product.Referencia}
                </h6>
              </div>

              {headerState["Cliente"] === "Minoristas" ? (
                Product.DescuentoMinorista !== 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <h3
                      className="FuenteBebas sinMargenes"
                      style={{ marginRight: "10px" }}
                    >
                      <strong>
                        ${" "}
                        {calcularDescuento(
                          Product.PrecioMinorista,
                          Product.DescuentoMinorista
                        )}
                      </strong>
                    </h3>
                    <h5
                      className="FuenteBebas sinMargenes"
                      style={{ textDecoration: "line-through", color: "red" }}
                    >
                      <strong>{Product.PrecioMinorista}</strong>
                    </h5>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <h3
                      className="FuenteBebas sinMargenes letero"
                      style={{ marginRight: "10px" }}
                    >
                      <strong>$ {Product.PrecioMinorista} </strong>
                    </h3>
                  </div>
                )
              ) : Product.DescuentoMayorista !== 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <h3
                    className="FuenteBebas sinMargenes"
                    style={{ marginRight: "10px" }}
                  >
                    <strong>
                      ${" "}
                      {calcularDescuento(
                        Product.PrecioMayorista,
                        Product.DescuentoMayorista
                      )}
                    </strong>
                  </h3>
                  <h5
                    className="FuenteBebas sinMargenes"
                    style={{ textDecoration: "line-through", color: "red" }}
                  >
                    <strong>{Product.PrecioMayorista}</strong>
                  </h5>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <h3
                    className="FuenteBebas sinMargenes letero"
                    style={{ marginRight: "10px" }}
                  >
                    <strong>$ {Product.PrecioMayorista}</strong>
                  </h3>
                </div>
              )}

              <GrupoTallas
                actualizarSessionStorage={actualizarSessionStorage}
                apiUrlState={apiUrlState}
                carrito={carrito}
                errorState={errorState}
                headerState={headerState}
                modificarApiUrlState={modificarApiUrlState}
                modificarCarritoState={modificarCarritoState}
                modificarErrorState={modificarErrorState}
                modificarHeaderState={modificarHeaderState}
                modificarSpinnerState={modificarSpinnerState}
                producto={Product}
                spinnerState={spinnerState}
                ViewDetalle={ViewDetalle}
                recursosUrlState={recursosUrlState}
                tallasState={tallasState}
                modificarTallasState={modificarTallasState}
                mostar={true}
              />

              <div>
                <div id="accordion">
                  <div>
                    <div
                      id="headingOne"
                      className="headerAcordeon FuenteHelvetica"
                      data-toggle="collapse"
                      data-target={"#Acordeon" + Product.Referencia}
                      aria-expanded="true"
                      aria-controls={"Acordeon" + Product.Referencia}
                      onClick={() => rotacionFlecha(Product.Referencia)}
                    >
                      <p style={{ paddingTop: "10px" }}>
                        <strong>
                          <b
                            className="FuenteHelvetica"
                            style={{ fontSize: "25px" }}
                          >
                            Mas información
                          </b>
                        </strong>
                      </p>
                      <img
                        src={
                          recursosUrlState +
                          "/img/Index/web/botones/flecha-abajo-negro.webp"
                        }
                        className="flechaAcordeon"
                        id={"Flecha" + Product.Referencia}
                        alt="Flecha"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            recursosUrlState +
                            "/img/Index/web/botones/flecha-abajo-negro.png";
                        }}
                      ></img>
                    </div>
                    <div
                      id={"Acordeon" + Product.Referencia}
                      className="collapse textoAcordeon show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <p
                        className="FuenteHelvetica"
                        style={{ fontSize: "20px" }}
                      >
                        <strong>
                          <b>Material: </b>
                        </strong>{" "}
                        {Product.Descripcion}
                      </p>
                      <img
                        src={
                          recursosUrlState +
                          "/img/Medidas/medidas" +
                          Product.Categoria.Categoria +
                          (Product.Genero === null
                            ? "UNISEX"
                            : Product.Genero.Genero) +
                          Product.Medidas +
                          ".webp"
                        }
                        alt="Medidas"
                        className="imgMedidas"
                        loading="lazy"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            recursosUrlState +
                            "/img/Medidas/medidasGeneral" 
                            // +
                            // Product.Categoria.Categoria +
                            // Product.Genero.Genero +
                            // Product.Medidas 
                            +
                            ".webp";
                        }}
                      />
                      <p
                        className="FuenteHelvetica"
                        style={{ fontSize: "20px" }}
                      >
                        <strong>
                          <b>Cuidados especiales: </b>
                        </strong>{" "}
                        Lavar en lavadora con agua fria 30ºC, ciclo delicado con
                        colores similares, lavar con jabón suave, no exprimir.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div
                    data-toggle="collapse"
                    data-target={"#Acordeon" + Product.Referencia}
                    aria-expanded="true"
                    aria-controls={"Acordeon" + Product.Referencia}
                    onClick={() => rotacionFlecha(Product.Referencia)}
                  >
                    <div className="tipoCompra">
                      <div className="objetoTipoCompra">
                        <img
                          src={
                            recursosUrlState +
                            "/img/Index/web/home/compra%20segura.png"
                          }
                          alt={Product.Referencia}
                          loading="lazy"
                          className="imgCompraProtegida"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              recursosUrlState +
                              "/img/General/compraProtegida.png";
                          }}
                        />
                        <div
                          className="vertical-line-producto"
                          id="lineaCompraProtegida"
                        ></div>
                        <p
                          className="FuenteHelvetica"
                          id="textoCompraProtegida"
                          style={{
                            fontSize: "20px",
                            width: "100%",
                            height: "50%",
                          }}
                        >
                          Compra protegida
                        </p>
                      </div>
                    </div>
                    <div className="tipoCompra">
                      <div className="objetoTipoCompra">
                        <img
                          src={recursosUrlState + "/img/Modal/pin-negro.webp"}
                          alt={Product.Referencia}
                          loading="lazy"
                          id="imgContraEntrega"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              recursosUrlState + "/img/Modal/pin-negro.png";
                          }}
                        />
                        <div
                          className="vertical-line-producto"
                          id="lineaContraEntrega"
                        ></div>
                        <p
                          className="FuenteHelvetica "
                          style={{
                            fontSize: "20px",
                            width: "100%",
                            height: "50%",
                          }}
                          id="textoContraEntrega"
                        >
                          Disponible pago contra entrega
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "space-around",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <div className="col-12 col-md-12 col-sm-12 col-lg-12">
              <p className="FuenteBebas TipografiaGeneral">
                Productos Relacionados
              </p>
            </div>
          </div>
          {ProductRel.length > 0 ? (
            <div className="row">
              {ProductRel.map((product, index) =>
                index <= 2 && product.Referencia !== id ? (
                  <div
                    className="col-4"
                    key={index}
                    style={{ textAlign: "center" }}
                  >
                    <a href={"/Detalle/" + product.Referencia}>
                      <img
                        className="ImgRelacionados"
                        src={
                          recursosUrlState +
                          "/img/Productos/Ref " +
                          product.Referencia +
                          ".webp"
                        }
                      />
                      <div className="RelDatos">
                        <p style={{ margin: "0px" }}>
                          {product.Categoria.Categoria} {product.Marca.Marca}
                        </p>
                      </div>
                      <div className="RelDatos">
                        <p>Ref {product.Referencia}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          fontSize: "20px",
                        }}
                      >
                        <p style={{ color: "black", fontFamily: "Bebas" }}>
                          $ {product.PrecioMinorista}
                        </p>
                      </div>
                    </a>
                  </div>
                ) : index <= 2 && ProductAux !== undefined ? (
                  <div
                    className="col-4"
                    key={index}
                    style={{ textAlign: "center" }}
                  >
                    <a href={"/Detalle/" + ProductRel[ProductAux].Referencia}>
                      <img
                        className="ImgRelacionados"
                        src={
                          recursosUrlState +
                          "/img/Productos/Ref " +
                          ProductRel[ProductAux].Referencia +
                          ".webp"
                        }
                      />
                      <div className="RelDatos">
                        <p style={{ margin: "0px" }}>
                          {ProductRel[ProductAux].Categoria.Categoria}{" "}
                          {ProductRel[ProductAux].Marca.Marca}
                        </p>
                      </div>
                      <div className="RelDatos">
                        <p>Ref {ProductRel[ProductAux].Referencia}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          fontSize: "20px",
                        }}
                      >
                        <p style={{ color: "black", fontFamily: "Bebas" }}>
                          $ {ProductRel[ProductAux].PrecioMinorista}
                        </p>
                      </div>
                    </a>
                  </div>
                ) : null
              )}
            </div>
          ) : null}
        </div>
      ) : null}
      <Footer></Footer>
    </div>
  );
};

export default Detalle;
