import { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import './static/css/Sucursal/Sucursal.css'
import axios from "axios";


const Sucursal = ({
  apiUrlState,
  clienteState,
  modificarClienteState,
  selectSucursal,
  setSelectSucursal,
  sucursales,
  setSucursales,
  setHabilitarForm,
  actualizar,
  setActualizar,
  modificarSpinnerState,
  focus = true,
}) => {

  /** Estado para manejo del formulario con react-hook-form */
  const { register, handleSubmit, setValue ,formState: {
    errors
  } } = useForm();

  // console.log(errors)

  const [departamentos, setDepartamentos] = useState([]);
  const [ciudades, setCiudades] = useState([]);

  const consultarDepartamentos = async (Pais) => {
    await axios
      .post(apiUrlState + "/Api/Departamento/ObtenerDepartamentos", {
        data: {
          Pais: Pais,
        },
      }).then((response)=>{
        setDepartamentos(response.data.data)
      });
  }

  const consultarCiudades = async (Departamento) => {
    await axios
    .post(apiUrlState + "/Api/Ciudad/ObtenerCiudades", {
      data: {
        Departamento: Departamento,
      },
    })
    .then((response) => {
      setCiudades(response.data.data);
    });
  }

  const consultarSucursales = async (numDoc) => {
    const url = `${apiUrlState}/Api/Sucursal/GetSucursales`;
    const response = await axios.post(url, { NumeroDocumento: numDoc });
    setSucursales(response.data.data);
    return response.data.data;
  }

  const llenarForm = async () => {
    if(selectSucursal !== "Principal" && selectSucursal !== "crear"){
      const initialValues = {
        Nombre: selectSucursal.Nombres,
        Email: selectSucursal.Correo,
        Celular: selectSucursal.Celular,
        Pais: selectSucursal.Ciudad.Departamento.Pais._id,
        Departamento: selectSucursal.Ciudad.Departamento._id,
        Ciudad: selectSucursal.Ciudad._id,
        Direccion: selectSucursal.Direccion,
        Barrio: selectSucursal.Barrio,
      }
      await consultarDepartamentos(initialValues.Pais);
      await consultarCiudades(initialValues.Departamento);

      Object.keys(initialValues).forEach((key) => {
        setValue(key, initialValues[key]);
      });
    } else {
      const initialValues = {
        Nombre: '',
        Email: '',
        Celular: '',
        Pais: '',
        Departamento: '',
        Ciudad: '',
        Direccion: '',
        Barrio: '',
      }
      Object.keys(initialValues).forEach((key) => {
        setValue(key, initialValues[key]);
      });
    }
    
  }

  const onSubmit = handleSubmit((async data => {
    modificarSpinnerState({ 
      Desplegar: true, Texto: "Actualizando datos de Sucursal" 
    });
    const DataSucursal = {
      NumeroDocumento: selectSucursal.NumeroDocumento,
      Nombres: data.Nombre,
      Correo: data.Email,
      Celular: data.Celular,
      Pais: data.Pais,
      Departamento: data.Departamento,
      Ciudad: data.Ciudad,
      Direccion: data.Direccion,
      Barrio: data.Barrio,
    }
    const url = `${apiUrlState}/Api/Sucursal/CrearSucursal`;
    const Data = {
      DataPrincipal: clienteState,
      DataSucursal: DataSucursal,
    }
    await axios.post(url, Data).then(async(response) => {
      const newDataSucursal = response.data;
      const sucursales2 = await consultarSucursales(clienteState.NumeroDocumento);
      if(selectSucursal === "crear" && !newDataSucursal.error) {
          const suc = sucursales2.find( doc => doc.NumeroDocumento === newDataSucursal.doc.NumeroDocumento);
          setSelectSucursal(suc);
          modificarClienteState({...clienteState, "Sucursal": newDataSucursal.doc.NumeroDocumento });   
      }
      console.log("Sucursal Actualizada")
      setActualizar( {Sucursal: false, Usuario: true});    
    }).catch((error)=> {
      console.log(error)
    })
    
  }))

  useEffect(() => {
    llenarForm();    
  }, [selectSucursal])
  
  useEffect(() => {
    if(actualizar.Sucursal) {
      onSubmit()
    }
  }, [actualizar])  
  

  return (
    <>
      <div className="formColumn" style={{ marginBottom: "5rem" }}>
        <div className="">
          <form onSubmit={ onSubmit } className="form-info">
            <div className="">
              <label htmlFor="nombre">(*) Nombre sucursal</label>
              <input
                // className={errors.Nombre? "error" : "correcto"}
                type="text"
                name="Nombre"
                id="nombre"
                {...register("Nombre", {
                  required: {
                    value: true,
                    message: "El nombre es requerido"
                  },
                })}
                autoFocus={focus}
              />
              {errors.Nombre && <span className="spanError">{errors.Nombre.message}</span>}
            </div>
            <div className="formulario">
              <label htmlFor="email">(*) Correo</label>
              <input
                type="email"
                name="Email"
                id="email"
                {...register("Email", {
                  required: {
                    value: true,
                    message: "El correo es requerido"
                  },
                })}
              />
              {errors.Email && <span className="spanError">{errors.Email.message}</span>}
            </div>
            <div className="formulario">
              <label htmlFor="telefono">(*) Número de celular</label>
              <input
                type="text"
                name="Celular"
                id="telefono"
                {...register("Celular", {
                  required: {
                    value: true,
                    message: "El teléfono es requerido"
                  },
                  minLength: {
                    value: 10,
                    message: "El teléfono debe tener 10 dígitos sin espacios"
                  },
                  maxLength: {
                    value: 10,
                    message: "El teléfono debe tener 10 dígitos sin espacios"
                  }
                })}
              />
              {errors.Celular && <span className="spanError">{errors.Celular.message}</span>}
            </div>

            <div className="pais formulario">
              <label htmlFor="pais" style={{ fontSize: "17px" }}>
                (*) País
              </label>
              <select
                className="fontSelect"
                placeholder="Pais"
                name="Pais"
                id="pais"
                {...register("Pais", {
                  required: {
                    value: true,
                    message: "El país es requerido"
                  },
                })}
                onChange={(e) => {consultarDepartamentos(e.target.value) }}
              >
                <option hidden value="">
                  Seleccione su país
                </option>
                <option value="60c8c846e76f272f6835f153">Colombia</option>
              </select>
              {errors.Pais && <span className="spanError">{errors.Pais.message}</span>}
            </div>
            <div className="departamento formulario">
              <label htmlFor="departamento">(*) Departamento</label>
              <select
                className="fontSelect"
                placeholder="Departamento"
                name="Departamento"
                id="departamento"
                {...register("Departamento", {
                  required: {
                    value: true,
                    message: "El departamento es requerido"
                  },
                })}
                onChange={(e) => { consultarCiudades(e.target.value) }}
              >
                <option hidden value="">
                  Seleccione su departamento
                </option>
                {departamentos.map((dpto)=>(
                  <option key={dpto.Codigo} value={dpto._id}>
                    {dpto.Nombre}
                  </option>
                ))}
              </select>
              {errors.Departamento && <span className="spanError">{errors.Departamento.message}</span>}
            </div>
            <div className="ciudad formulario">
              <label htmlFor="ciudad" style={{ fontSize: "17px" }}>
                (*) Ciudad
              </label>
              <select
                className="fontSelect"
                placeholder="Ciudad"
                name="Ciudad"
                id="ciudad"
                onChange={(e) => { }}
                {...register("Ciudad", {
                  required: {
                    value: true,
                    message: "La ciudad es requerida"
                  },
                })}
              >
                <option hidden value="">
                  Seleccione su ciudad
                </option>
                {ciudades.map((ciudad) => (
                  <option key={ciudad.Codigo} value={ciudad._id}>
                    {ciudad.Nombre}
                  </option>
                ))}
              </select>
              {errors.Ciudad && <span className="spanError">{errors.Ciudad.message}</span>}
            </div>

            <div
              className="direccion"
              style={{ fontSize: "17px" }}
            >
              <label htmlFor="direccion">(*) Dirección</label>
              <input
                type="text"
                placeholder="Ingrese su dirección"
                name="Direccion"
                onChange={(e) => {}}
                id="direccion_input"
                {...register("Direccion", {
                  required: {
                    value: true,
                    message: "La dirección es requerida"
                  },
                  maxLength: {
                    value: 50,
                    message: "La direccióm debe tener maximo 50 caracteres."
                  }
                })}
              ></input>
              {errors.Direccion && <span className="spanError">{errors.Direccion.message}</span>}
            </div>
            <div
              className="barrio"
              style={{ fontSize: "17px" }}
            >
              <label htmlFor="barrio">(*) Barrio</label>
              <input
                type="text"
                placeholder="Ingrese su barrio"
                name="Barrio"
                onChange={(e) => {}}
                id="barrio_input"
                {...register("Barrio", {
                  required: {
                    value: true,
                    message: "El barrio es requerido"
                  },
                  maxLength: {
                    value: 25,
                    message: "El barrio debe tener maximo 20 caracteres."
                  }
                })}
              ></input>
              {errors.Barrio && <span className="spanError">{errors.Barrio.message}</span>}
            </div>
            {/* <div style={{textAlign:"center", marginTop:"20px"}}>
              <button 
                type="submit"
                className="btn btn-dark"
              >
                {selectSucursal==="crear"? "Crear sucursal" : "Actualizar sucursal"}
              </button>
            </div> */}
          </form>

        </div>
      </div>
    </>
  )
}

export default Sucursal;