import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FaClock, FaTimesCircle  } from 'react-icons/fa';
import './static/styles.css'

const PaymentRejected = (props) => {
    const { status, reference, date } = props;
    const [Cliente, setCliente] = useState(null);
    useEffect(() => {
        const variableFromLocalStorage = localStorage.getItem('Cliente');
        setCliente(JSON.parse(variableFromLocalStorage));
        console.log("Cliente", JSON.parse(variableFromLocalStorage).NumeroDocumento)
    }, []);
    return (
        <div class="login">
            <div class="form-container">
                <FaTimesCircle size={50} class="logo"/>
                <h1 className='FuenteBebas text-center'>Hola {Cliente?.Nombres}</h1>
                <p class="subtitle">Tu Transaccion fue rechazada :c</p>
                <p
                    className="FuenteBebas text-center"
                >
                    Pero no te preocupes puedes comunicarte con nosotros al{" "}
                    <b>+57 301 661 1801</b> o haz click en el siguiente{" "}
                    <a href="https://api.whatsapp.com/send?phone=573016611801&text=Hola Croatta quisiera más información para hacer mi compra"> enlace a WhatsApp</a>
                </p>
                <div className=''>
                    <p
                        className="botonPaso2 text-center FuenteBebas"
                        style={{ cursor: "pointer" }}
                        onClick={() => window.location.href = "/"}
                    >
                        Volver al Home
                    </p>
                </div>

            </div>
        </div>
    );
};

export default PaymentRejected;