import axios from 'axios';
import React, { useState, useEffect } from 'react'        
import { usePreventaContext } from '../../providers/PreventaProviders';

const LoginVendedor = ({
  apiUrlState,
  modificarApiUrlState,
  headerState,
  modificarHeaderState,
  errorState,
  modificarErrorState,
  actualizarSessionStorage,
}) => {

  // states
    const { vendedor, modificarVendedorState } = usePreventaContext();
    const [userData, setUserData] = useState({ email: "", password: "" });
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState("");


    // Actualizacion de campos de formulario
    const HandleChange = (e) => {
      // console.log("[e.target.name]", e.target.name)
      // console.log("[e.target.value]", e.target.value)
      let value = e.target.value;
      setUserData({ ...userData, [e.target.name]: value.toLowerCase() });
    };

    useEffect(() => {
      if(vendedor) {
        window.location.href = "/Preventa/Catalogo"
      }
    }, [])
    

    const onSubmit = async( e ) => {
      e.preventDefault();
      if(userData.email !== "" && userData.password !== "") {
        setLoading(true);
        setWarning("");
        const respuesta = await axios.post(apiUrlState + `/Api/Vendedor/LoginVendedor`, userData);
        const Vendedor = respuesta.data.data;
        localStorage.setItem("Vendedor", JSON.stringify(Vendedor));
        if(Vendedor !== null) {
          // localStorage.setItem("Vendedor", JSON.stringify(Vendedor));
          localStorage.setItem("TipoCliente", "Mayoristas");
          localStorage.removeItem("Carrito");
          localStorage.setItem("Bodega", "13");
          modificarVendedorState(Vendedor);
          modificarErrorState({
            Error: true,
            Mensaje: "Bienvenido Vendedor",
            Vendedor: Vendedor.Nombre
          });
          modificarHeaderState({
            ...headerState,
            Cliente: "Mayoristas"
          });
          setTimeout(()=>{
            window.location.href = "/Preventa/Catalogo";
          },2000);
        } else {
          setLoading(false);
          setWarning("Datos incorrectos");
        }

      }
    }


    return (
        <div className="IniciarSesion">
          <div className="formulariosCampoSesion">
            <div className="formularioSesion">
              <form onSubmit={(e) => onSubmit(e)} className="form-info">
                <div>
                  <p
                    className="text-center FuenteBebas"
                    style={{ fontSize: "50px" }}
                  >
                    Inicia Sesión
                  </p>
                </div>
                <div className="formularioIndividual">
                  <input
                    onChange={(e) => HandleChange(e)}
                    // value={email}
                    className="text-center"
                    type="text"
                    placeholder="Ingrese su correo electrónico"
                    name="email"
                    id="usuario_input"
                  ></input>
                </div>
                <div className="formularioIndividual">
                  <input
                    onChange={(e) => HandleChange(e)}
                    className="text-center"
                    // value={password}
                    type="password"
                    placeholder="Ingrese su contraseña"
                    name="password"
                    id="contraseña_input"
                  ></input>
                </div>
                
                  <button
                    className="botonIngresar text-center FuenteBebas"
                    style={{ cursor: "pointer" }}
                    
                  >
                    {loading ? "Cargando..." : "Iniciar Sesión"}
                  </button>
                  <p className='text-center mt-2' style={{color:"red"}}>{warning}</p>
              </form>
            </div>
          </div>
          {/* <div>
            <p
              className="text-center FuenteHelvetica registroLink"
              style={{ cursor: "pointer", fontSize: "15px" }}
            //   onClick={() => cambioRegistro()}
            >
              ¿Nuevo? Regístrate ahora.
            </p>
          </div> */}
        </div>
    )
    
}

export default LoginVendedor;