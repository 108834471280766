import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';


const PaymentConfirmation = () => {
  const [Cliente, setCliente ]= useState(null);
  useEffect(() => {
    const variableFromLocalStorage = localStorage.getItem('Cliente');
    setCliente(JSON.parse(variableFromLocalStorage));
    console.log("Cliente", JSON.parse(variableFromLocalStorage).NumeroDocumento)
  }, []);

  return (
    <div>

      <div class="login">
            <div class="form-container">
                <FontAwesomeIcon class="logo" icon={faCheckCircle} />
                {/* <img src="./logos/logo_yard_sale.svg" alt="logo" class="logo"/> */}
                <h1 className='FuenteBebas text-center'>Hola {Cliente?.Nombres}</h1>
                <p class="subtitle">Tu Transaccion se realizó exitosamente</p>
                <p class="subtitle">Ahora estamos empacando tu pedido</p>
                <p
                    className="FuenteBebas text-center"
                >
                    Cuando realices el pago envíanos el
                    comprobante por WhatsApp al siguiente número{" "}
                    <b>+57 301 661 1801</b> o haz click en el siguiente{" "}
                    <a href="https://api.whatsapp.com/send?phone=573016611801&text=Hola Croatta quisiera más información para hacer mi compra"> enlace a WhatsApp</a>
                </p>
                <p className="FuenteBebas text-center">¡Gracias por tu compra!</p>
                <div className=''>
                    <p
                        className="botonPaso2 text-center FuenteBebas"
                        style={{ cursor: "pointer" }}
                        onClick={() => window.location.href("https://api.whatsapp.com/send?phone=573016611801&text=Hola Croatta quisiera más información para hacer mi compra")}
                    >
                        Volver al Home
                    </p>
                </div>

            </div>
        </div>
    </div>
  );
};

export default PaymentConfirmation;