//Zona de importacion de componentes externos.
import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
import "./static/css/footer.css";

//Zona de importacion de componentes internos.

function Footer() {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      <div className="footer">
        <ul className="social-network social-circle">
          <li>
            <a
              className="icoLinkedin"
              href="https://www.linkedin.com/company/croatta/mycompany/"
              title="Linkedin"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </li>

          <li>
            <a
              className="icoMedium"
              href="https://api.whatsapp.com/send?phone=573016611801&text=Hola Croatta quisiera más información para hacer mi compra"
              title="Medium"
            >
              <i className="fa fa-whatsapp"></i>
            </a>
          </li>

          <li>
            <a
              className="icoFacebook"
              href="https://es-la.facebook.com/944332618912688/shop/all_products/?ref_code=mini_shop_page_card_cta&ref_surface=mini_shop_storefront"
              title="Facebook"
            >
              <i className="fa fa-facebook"></i>
            </a>
          </li>
          <li>
            <a
              className="icoInstagram"
              href="https://www.instagram.com/croatta_"
              title="Instagram"
            >
              <i className="fa fa-instagram"></i>
            </a>
          </li>
        </ul>
        <p className="cp-text">
          © Copyright 2024. The Croatta Company. All rights reserved.
        </p>
      </div>
    </>
  );
}
export default Footer;
