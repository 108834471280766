import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import EpaycoButton from "../Epayco";

import "./static/css/newIndex.css";
import CarouselInfo from "./CarouselInfo";
import { useCategContext } from "../../providers/CategoriasProviders";

const NewIndex = ({
  headerState,
  modificarHeaderState,
  categoriasState,
  marcasState,
  modificarCategoriasState,
  modificarMarcasState,
  productosState,
  modificarProductosState,
  spinnerState,
  modificarSpinnerState,
  errorState,
  modificarErrorState,
  apiUrlState,
  clienteState,
  modificarClienteState,
}) => {
  const [fondoss] = useState([])
  const [fondos] = useState([
    [
      "https://hosting.croatta.com/img/Index/web/home/1_1.webp",
      "https://hosting.croatta.com/img/Index/web/home/1.webp",
      "https://hosting.croatta.com/img/Index/web/home/1_2.webp",
      "https://hosting.croatta.com/img/Index/web/home/1_3.webp",
      "REBAJAS",
      "/Mercado/null/null",
      null,
      null,
      null,
      null,
      false,
      null,
      null,
      true,
    ],
    [
      "https://hosting.croatta.com/img/Index/web/home/2_1.webp",
      "https://hosting.croatta.com/img/Index/web/home/2.webp",
      "https://hosting.croatta.com/img/Index/web/home/2_2.webp",
      "https://hosting.croatta.com/img/Index/web/home/2_3.webp",
      "DISEÑOS EXCLUSIVOS",
      "/Mercado/Estampada/Hombre",
      { Categoria: "607ed63e521b1c0910a581aa" },
      { Subcategoria: "607ed65a521b1c0910a581bf" },
      null,
      { Genero: "607ed653521b1c0910a581b8" },
      false,
      "Hombre",
      "Estampada",
      false,
    ],
    [
      "https://hosting.croatta.com/img/Index/web/home/3_1.webp",
      "https://hosting.croatta.com/img/Index/web/home/3.webp",
      "https://hosting.croatta.com/img/Index/web/home/3_2.webp",
      "https://hosting.croatta.com/img/Index/web/home/3_3.webp",
      "REBAJAS",
      "/Mercado/null/null",
      null,
      null,
      null,
      null,
      false,
      null,
      null,
      true,
    ],
    [
      "https://hosting.croatta.com/img/Index/web/home/Principal1.webp",
      "https://hosting.croatta.com/img/Index/web/home/Principal2.webp",
      "https://hosting.croatta.com/img/Index/web/home/Principal3.webp",
      "https://hosting.croatta.com/img/Index/web/home/Principal4.webp",
      "REBAJAS",
      "/Mercado/null/null",
      null,
      null,
      null,
      null,
      false,
      null,
      null,
      true,
    ],
  ]);
  // const [categorias] = useState([
  //   // [
  //   //   "NEW",
  //   //   "https://hosting.croatta.com/img/Index/web/carousel/fondo2Movil.png",

  //   //   null,
  //   //   null,
  //   //   null,
  //   //   null,
  //   //   true,
  //   // ],
  //   [
  //     "CAMISETAS ESTAMPADAS HOMBRE",
  //     "https://hosting.croatta.com/img/Index/web/home/CATEGORIA%20ESTAMPADAS%20HOMBRE.webp",
  //     { Categoria: "607ed63e521b1c0910a581aa" },
  //     { Subcategoria: "607ed65a521b1c0910a581bf" },
  //     null,
  //     { Genero: "607ed653521b1c0910a581b8" },
  //     false,
  //     "Hombre",
  //     "Estampada",
  //     false,
  //   ],
  //   [
  //     "CAMISETAS ESTAMPADAS MUJER",
  //     "https://hosting.croatta.com/img/Index/web/home/CATEGORIA%20ESTAMPADAS%20MUJER.webp",
  //     { Categoria: "607ed63e521b1c0910a581aa" },
  //     null,
  //     null,
  //     { Genero: "607ed653521b1c0910a581b9" },
  //     false,
  //     "Mujer",
  //     "Camiseta",
  //     false,
  //   ],

  //   [
  //     "CAMISETAS BÁSICAS HOMBRE",
  //     "https://hosting.croatta.com/img/Index/web/home/CATEGORIA%20BASICAS.webp",
  //     { Categoria: "607ed63e521b1c0910a581aa" },
  //     { Subcategoria: "607ed65a521b1c0910a581bd" },
  //     [{ Marca: "607ed64c521b1c0910a581b3" }],
  //     { Genero: "607ed653521b1c0910a581b8" },
  //     false,
  //     "Hombre",
  //     "Basica",
  //     false,
  //   ],

  //   [
  //     "BLUSAS BÁSICAS DK",
  //     "https://hosting.croatta.com/img/Index/web/home/BLUSAS%20BASICAS.webp",
  //     { Categoria: "651aec4251072238a0969749" },
  //     { Subcategoria: "607ed65a521b1c0910a581bd" },
  //     [{ Marca: "64f8df51ab8f6199b7154137" }],
  //     { Genero: "607ed653521b1c0910a581b9" },
  //     false,
  //     "Mujer",
  //     "Blusa",
  //     false,
  //   ],

  //   // [
  //   //   "CAMISETAS ESTAMPADAS DK",
  //   //   "https://hosting.croatta.com/img/Index/web/home/BasicasM.png",
  //   //   { Categoria: "607ed63e521b1c0910a581aa" },
  //   //   { Subcategoria: "607ed65a521b1c0910a581bf" },
  //   //   [{ Marca: "64f8df51ab8f6199b7154137" }],
  //   //   { Genero: "607ed653521b1c0910a581b9" },
  //   //   false,
  //   //   "Mujer",
  //   //   "CamisetaEsDk",
  //   //   false,
  //   // ],

  //   // [
  //   //   "VALTIMOR",
  //   //   "https://hosting.croatta.com/img/Index/web/home/Camisetas%20hombre.png",
  //   //   { Categoria: "607ed63e521b1c0910a581aa" },
  //   //   null,
  //   //   [
  //   //     { Marca: "607ed64c521b1c0910a581b6" }, //fishbone
  //   //     { Marca: "607ed64c521b1c0910a581b5" }, //staff
  //   //     { Marca: "607ed64c521b1c0910a581b4" }, //urbi
  //   //     { Marca: "607ed64c521b1c0910a581b7" }, //valtimor
  //   //   ],
  //   //   { Genero: "607ed653521b1c0910a581b8" },
  //   //   false,
  //   // ],

  //   [
  //     "POLOS",
  //     "https://hosting.croatta.com/img/Index/web/home/CATEGORIA%20POLOS.webp",
  //     { Categoria: "607ed63f521b1c0910a581b1" },
  //     null,
  //     null,
  //     null,
  //     false,
  //     "Hombre",
  //     "Polo",
  //     false,
  //   ],
  //   [
  //     "GORRAS",
  //     "https://hosting.croatta.com/img/Index/web/home/CATEGORIA%20GORRAS.webp",
  //     { Categoria: "607ed63e521b1c0910a581ac" },
  //     null,
  //     null,
  //     null,
  //     false,
  //     "Unisex",
  //     "Gorra",
  //     false,
  //   ],

  //   [
  //     "VESTIDOS",
  //     "https://hosting.croatta.com/img/Index/web/home/VESTIDOS.webp",
  //     { Categoria: "65578d4701bf5211b63d14bc" },
  //     { Subcategoria: "607ed65a521b1c0910a581bc" },
  //     [{ Marca: "64f8df51ab8f6199b7154137" }],
  //     { Genero: "607ed653521b1c0910a581b9" },
  //     false,
  //     "Mujer",
  //     "Vestido",
  //     false,
  //   ],

  //   [
  //     "LYCRAS",
  //     "https://hosting.croatta.com/img/Index/web/home/LYCRAS.webp",
  //     { Categoria: "6525a7906b44bb8bf3323210" },
  //     null,
  //     null,
  //     null,
  //     false,
  //     "Mujer",
  //     "Lycra",
  //     false,
  //   ],
  //   // [
  //   //   "PANTALONETAS",
  //   //   "https://hosting.croatta.com/img/Index/web/home/Pantalonetas.png",
  //   //   { Categoria: "607ed63e521b1c0910a581b0" },
  //   //   null,
  //   //   null,
  //   //   null,
  //   //   false,
  //   //   "Hombre",
  //   //   "Pantalonetas",
  //   // ],
  //   // [
  //   //   "HOODIES HOMBRE",
  //   //   "https://hosting.croatta.com/img/Index/web/home/Hoodies%20Hombre.png",
  //   //   { Categoria: "607ed63e521b1c0910a581ad" },
  //   //   null,
  //   //   null,
  //   //   { Genero: "607ed653521b1c0910a581b8" },
  //   //   false,
  //   //   "Hombre",
  //   //   "Hoodie",
  //   // ],
  //   // [
  //   //   "HOODIES MUJER",
  //   //   "https://hosting.croatta.com/img/Index/web/home/Hoodies%20Mujer.png",
  //   //   { Categoria: "607ed63e521b1c0910a581ad" },
  //   //   null,
  //   //   null,
  //   //   { Genero: "607ed653521b1c0910a581b9" },
  //   //   false,
  //   //   "Mujer",
  //   //   "Hoodie",
  //   // ],
  //   // [
  //   //   "JOGGER HOMBRE",
  //   //   "https://hosting.croatta.com/img/Index/web/home/Jogger%20hombre.png",
  //   //   { Categoria: "607ed63e521b1c0910a581af" },
  //   //   null,
  //   //   null,
  //   //   { Genero: "607ed653521b1c0910a581b8" },
  //   //   false,
  //   //   "Hombre",
  //   //  "Jogger",
  //   // ],
  //   // [
  //   //   "JOGGER MUJER",
  //   //   "https://hosting.croatta.com/img/Index/web/home/Jogger%20mujer.png",
  //   //   { Categoria: "607ed63e521b1c0910a581af" },
  //   //   null,
  //   //   null,
  //   //   { Genero: "607ed653521b1c0910a581b9" },
  //   //   false,
  //   //   false,
  //   //   "Mujer",
  //   //   "Jogger",
  //   // ],
  //   // [
  //   //   "NIÑOS",
  //   //   "https://hosting.croatta.com/img/Index/web/carousel/fondo3Movil.png",
  //   //   null,
  //   //   null,
  //   //   null,
  //   //   { Genero: "607ed653521b1c0910a581ba" },
  //   //   false,
  //   //  "Niño",
  //   //  "Camiseta",
  //   // ],
  //   // [
  //   //   "REBAJAS",
  //   //   "https://hosting.croatta.com/img/Index/web/home/Descuentos.webp",
  //   //   null,
  //   //   null,
  //   //   null,
  //   //   null,
  //   //   false,
  //   //   null,
  //   //   null,
  //   //   true,
  //   // ],
  // ]);

  const categorias = useCategContext();

  const images = {
    img1: [
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL1.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL2.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL3.webp",
    ],
    img2: [
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL21.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL22.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL23.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL24.webp",
    ],
    img3: [
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL31.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL32.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL33.webp",
      "https://hosting.croatta.com/img/Index/web/home/BANNERPRINCIPAL34.webp",
    ]

  }

  const aleatorio= (max)=>{
    return Math.floor(Math.random() * max);
  }
  const CambioProductos = async (
    categoria,
    subcategoria,
    marca,
    genero,
    nuevo = false,
    descuento = false
  ) => {
    modificarProductosState([
      { CambioSeccion: false, Busqueda: false, irInicio: true },
      [],
    ]);
    let url = apiUrlState + "/Api/Producto/GetProductos";
    let data = {};

    if (nuevo) {
      data = {
        Categorias: [],
        Generos: [],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "",
        ProductosNuevos: true,
        Descuentos: false,
        Todo: false,
      };
    } else if (descuento) {
      data = {
        Categorias: [],
        Generos: [],
        Marcas: [],
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: [],
        ParametroOrdenamiento: "",
        ProductosNuevos: false,
        Descuentos: true,
        Todo: false,
      };
    } else {
      data = {
        Categorias: categoria === null ? [] : [categoria],
        Generos: genero === null ? [] : [genero],
        Marcas: marca === null ? [] : marca,
        Medidas: [],
        Tallas: [],
        Colores: [],
        Subcategoria: subcategoria === null ? [] : [subcategoria],
        ParametroOrdenamiento: "Marca",
        Descuentos: false,
        ProductosNuevos: false,
      };
    }

    if (!spinnerState["Desplegar"]) {
      modificarSpinnerState({
        Desplegar: !spinnerState["Desplegar"],
        Texto: "Cargando productos...",
      });
    }
    await axios
      .post(
        url,
        {
          data,
        },
        {}
      )
      .then((response) => {
        modificarProductosState([
          { CambioSeccion: false, Busqueda: false, irInicio: true },
          response.data.data,
        ]);
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
        if (errorState["Error"]) {
          modificarErrorState({
            Error: false,
            Mensaje: "Todo bien, todo correcto",
          });
        }
      })
      .catch(function (err) {
        if (!errorState["Error"])
          modificarErrorState({
            Error: true,
            Mensaje: "Error al recuperar datos del servidor",
          });
        modificarSpinnerState({
          Desplegar: false,
          Texto: spinnerState["texto"],
        });
      });
  };

  useEffect(() => {
    modificarHeaderState({ ...headerState, Cliente: "Minoristas" });
  }, []);


  // Cambio temporal para el concurso de diseño
  // const [fondo, setFondo] = useState(aleatorio(fondos.length)); // descomentar
  const [fondo, setFondo] = useState(3); // comentar


  useEffect(() => {    
    console.log("asd", fondo);
  }, []);

  return (
    <>
      <CarouselInfo index={true} images={images}/>
      <a
        href="/Mercado/Nuevo/New"
        onClick={() => CambioProductos(null, null, null, null, true, false)}
      >
        {/* <h2
          id="Boton"
          className="FuenteBebas centradoBoton"
          style={{
            textAlign: "center",
            backgroundColor: "transparent",
            textShadow:
              "0.1em 0.1em 0.1em black, 0.1em 0.1em 0.2em black, 0.1em 0.1em 0em black",
          }}
        >
          NEW
        </h2> */}
        <h2 style={{ color:"black", fontFamily: "Arial_Bold", textAlign: "center", margin: "1vh", fontSize: "2rem"}}>
          NUEVA COLECCIÓN DISPONIBLE
        </h2>
      </a>
      <div className="center">
        
        {/* </Link> */}
        <div id="">
          <div id="filaIndex" className="" style={{ padding: "1vw", paddingTop: "0px" }}>
            {categorias.map((categoria) => (
              <a
                href={"/Mercado/" + categoria[8] + "/" + categoria[7]}
                onClick={() => {}
                  // CambioProductos(
                  //   categoria[2],
                  //   categoria[3],
                  //   categoria[4],
                  //   categoria[5],
                  //   categoria[6],
                  //   categoria[9]
                  // )
                }
              >
                <div className="contenedorCarrito">
                  <picture>
                    <source srcSet={categoria[1]} media="(min-width: 1400px)" />
                    {/* 2000x500 */}
                    <source srcSet={categoria[1]} media="(min-width: 769px)" />
                    {/* 1400x500 */}
                    <source srcSet={categoria[1]} media="(min-width: 577px)" />
                    {/* 800x600 */}
                    <img
                      srcSet={categoria[1]}
                      alt={categoria[0]}
                      className="d-block img-fluid"
                    />
                    {/* 600x500 */}
                  </picture>

                  {/* <h2
                    id="Boton"
                    className="FuenteBebas centradoBotonIndex"
                    style={{ textAlign: "center" }}
                  >
                    {categoria[0]}
                  </h2> */}
                </div>
              </a>
            ))}
          </div>
        </div>
        {/* <div>
          <EpaycoButton />
        </div>  */}
        <Footer></Footer>
      </div>
    </>
  );
};

export default NewIndex;
