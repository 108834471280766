import { useState, useContext, createContext } from 'react'

const PreventaContext = createContext();

/** contexto que contiene las variables de uso para preventa */
export const usePreventaContext = () => {
  return useContext(PreventaContext)
}

export const PreventaProvider = ({ children }) => {
  //** Estados para manejo de vendedores */
  let parsedBodega
  const localStorageBodega = localStorage.getItem("Bodega");
  if (!localStorageBodega) {
    parsedBodega = "3"
  } else {
    parsedBodega = localStorageBodega;
  }
  const [Bodega, setBodega] = useState(parsedBodega);

  let parsedVendedor;
  const localStorageVendedor = localStorage.getItem("Vendedor");
  if (!localStorageVendedor) {
    // localStorage.setItem('Vendedor', JSON.stringify([]));
    parsedVendedor = null
  } else {
    parsedVendedor = JSON.parse(localStorageVendedor);

  }
  const [vendedor, modificarVendedorState] = useState(parsedVendedor);

  /** Seleccion de bodega para catalogos */
  let parsedBodegaC
  const localStorageBodegaC = localStorage.getItem("BodegaCatalogos");
  if (!localStorageBodegaC) {
    parsedBodegaC = "3"
  } else {
    parsedBodegaC = localStorageBodegaC;
  }
  const [BodegaCatalogos, setBodegaCatalogos] = useState(parsedBodegaC);

  const [metodoFact, setMetodoFact] = useState({
    Facturacion: 0,
    Remision: 100
  });

  //** END Estados para manejo de vendedores */
  return (
    <PreventaContext.Provider value={
      {
        Bodega,
        setBodega,
        vendedor,
        modificarVendedorState,
        metodoFact,
        setMetodoFact,
        BodegaCatalogos,
        setBodegaCatalogos,
      }
    }>
      {children}
    </PreventaContext.Provider>
  )
}