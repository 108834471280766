import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FaClock } from 'react-icons/fa';
import './static/styles.css'

const PaymentPending = () => {

    const [Cliente, setCliente] = useState(null);
    useEffect(() => {
        const variableFromLocalStorage = localStorage.getItem('Cliente');
        setCliente(JSON.parse(variableFromLocalStorage));
        console.log("Cliente", JSON.parse(variableFromLocalStorage).NumeroDocumento)
    }, []);

    useEffect(() => {
        console.log("Cliente", Cliente?.Nombres)
    }, [Cliente]);
    return (
        <div class="login">
            <div class="form-container">
                {/* <img src="./logos/logo_yard_sale.svg" alt="logo" class="logo"/> */}
                {/* <FontAwesomeIcon class="logo" icon={faCheckCircle} /> */}
                <FaClock  size={50} class="logo"/>
                <h1 className='FuenteBebas text-center'>Hola {Cliente?.Nombres}</h1>
                <p class="subtitle">Tu Transaccion se encuentra en estado pendiente de pago</p>
                <p
                    className="FuenteBebas text-center"
                >
                    Cuando realices el pago envíanos el
                    comprobante por WhatsApp al siguiente número{" "}
                    <b>+57 301 661 1801</b> o haz click en el siguiente{" "} 
                    <a href="https://api.whatsapp.com/send?phone=573016611801&text=Hola Croatta quisiera más información para hacer mi compra"> enlace a WhatsApp</a>
                </p>
                <p className="FuenteBebas text-center">¡Gracias por tu compra!</p>
                <div className=''>
                    <p
                        className="botonPaso2 text-center FuenteBebas"
                        style={{ cursor: "pointer" }}
                        onClick={() => window.location.href = "/"}
                    >
                        Volver al Home
                    </p>
                </div>

            </div>
        </div>

    );
};

export default PaymentPending;